import React from 'react';
import locationHOC from '../locationProvider/locationHOC';
import ProductTable from '../project/ProductTable';
import {createHeaderActionsPC} from '../products/ProductTableWrapper';
import {getQueryParam, getQueryParamNumber, navigateToParametrized, createSetQueryParamValue} from '../../lib/url';
import {QUERY_PARAMS} from '../../constants/navigation';
import TradeActions from '../products/table/TradeActions';
import EditActions from '../products/table/EditActions';

/**
 * @fero
 */

class ContractProductDisplay extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isRefPriceVisible: true,
        }
    }

    toggleRefPriceVisibility = () => {
        const {isRefPriceVisible} = this.state;
        this.setState({
            isRefPriceVisible: !isRefPriceVisible,
        })
    };

    render() {
        const {location, products, reload} = this.props;
        const {isRefPriceVisible} = this.state;
        const manufacturerId = getQueryParamNumber(location, QUERY_PARAMS.CONTRACT_ID_MANUFACTURER);
        const refCustomerId = getQueryParamNumber(location, QUERY_PARAMS.CONTRACT_ID_REF_CUSTOMER);
        const orderBy = getQueryParam(location, QUERY_PARAMS.CONTRACT_ORDER_BY);
        const limit = getQueryParamNumber(location, QUERY_PARAMS.CONTRACT_LIMIT);
        const offset = getQueryParamNumber(location, QUERY_PARAMS.CONTRACT_OFFSET);
        const avail = getQueryParam(location, QUERY_PARAMS.CONTRACT_AVAILABILITY);

        return <ProductTable
            products={products}
            reload={reload}
            isRefPriceVisible={isRefPriceVisible}
            HeaderActions={createHeaderActionsPC(
                {
                    isRefPriceVisible: isRefPriceVisible, 
                    toggleRefPriceVisibility: this.toggleRefPriceVisibility,
                }
            )}
            InfoRowActions={EditActions}
            MainRowActions={TradeActions}
            availability={avail}
            setAvailability={createSetQueryParamValue(location, QUERY_PARAMS.CONTRACT_AVAILABILITY)}
            limit={limit}
            changeLimit={createSetQueryParamValue(location, QUERY_PARAMS.CONTRACT_LIMIT)}
            offset={offset}
            changeOffset={(newOffs) => {
                navigateToParametrized(location, null, {
                    [QUERY_PARAMS.CONTRACT_OFFSET]: newOffs,
                    [QUERY_PARAMS.CONTRACT_SCROLL]: 0,
                });
            }}
            manufacturerId={manufacturerId}
            setManufacturerId={createSetQueryParamValue(location, QUERY_PARAMS.CONTRACT_ID_MANUFACTURER)}
            refCustomerId={refCustomerId}
            setRefCustomerId={createSetQueryParamValue(location, QUERY_PARAMS.CONTRACT_ID_REF_CUSTOMER)}
            orderBy={orderBy}
            setOrderBy={createSetQueryParamValue(location, QUERY_PARAMS.CONTRACT_ORDER_BY)}
        />;
    }
}

export default locationHOC(ContractProductDisplay);