import React from 'react';
import Helmet from '../project/Helmet';
import ContractProductDisplay from './ContractProductDisplay';
import withDataHOC from '../dataProvider/withDataHOC';
import locationHOC from '../locationProvider/locationHOC';
import SearchByLocation from '../general/SearchByLocation';
import {getQueryParam, getQueryParamNumber} from '../../lib/url';
import {GLOBAL_DATA} from '../../constants/globalData';
import {QUERY_PARAMS} from '../../constants/navigation';
import {Trans, t} from '@lingui/macro';
import TableWithFiltersLayout from '../project/TableWithFiltersLayout';

/**
 * @fero
 */

class ContractPage extends React.PureComponent {

    render() {
        const {
            [GLOBAL_DATA.CONTRACT_PRODUCTS]: products, 
            [GLOBAL_DATA.CUSTOMERS]: customers, 
            [GLOBAL_DATA.RELOAD_DATA]: reload, 
            location
        } = this.props;
        const designation = getQueryParam(location, QUERY_PARAMS.CONTRACT_DESIGNATION);
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ID_CUSTOMER);
        const customer = customerId != null && customers != null ? customers.find(c => c.id == customerId) : null;

        return <React.Fragment>
            <Helmet
                title={t`Kontrakt` + (customer != null ? ' ' + customer.name : '')}
            />
            {
                designation != null || (products != null && products.lines != null && products.lines.length > 0) ?
                <TableWithFiltersLayout
                    title={<Trans>Kontrakt</Trans>}
                    params={
                        <div className="m-3 modal-product-search">
                            <SearchByLocation
                                textQueryParamKey={QUERY_PARAMS.CONTRACT_DESIGNATION}
                                typeQueryParamKey={QUERY_PARAMS.CONTRACT_SEARCH_TYPE}
                                paginationPageKey={QUERY_PARAMS.CONTRACT_LIMIT}
                                additionalParams={{
                                    [QUERY_PARAMS.CONTRACT_ORDER_BY]: undefined,
                                    [QUERY_PARAMS.CONTRACT_LIMIT]: 20,
                                    [QUERY_PARAMS.CONTRACT_OFFSET]: undefined,
                                    [QUERY_PARAMS.CONTRACT_SCROLL]: undefined,
                                }}
                            />
                        </div>
                    }
                    table={
                        <ContractProductDisplay
                            products={products}
                            reload={() => {
                                reload([GLOBAL_DATA.CONTRACT_PRODUCTS])
                            }}
                        />
                    }
                    scrollParam={QUERY_PARAMS.CONTRACT_SCROLL}
                /> :
                <div className="m-5 d-flex justify-content-center">
                    <h1 className="d-inline color-disabled">
                    <Trans>Momentálne neevidujeme žiadny kontrakt.</Trans>
                    </h1>
                </div>
            }
        </React.Fragment>;
    }

}

export default locationHOC(withDataHOC([
    GLOBAL_DATA.CONTRACT_PRODUCTS,
    GLOBAL_DATA.CUSTOMERS, 
    GLOBAL_DATA.RELOAD_DATA
])(ContractPage));