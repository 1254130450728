import React from 'react';
import PropTypes from 'prop-types';
import deepEqual  from 'deep-equal';
import {SEARCH_TYPE} from '../../constants/search';
import {Button, Input, Select} from 'antd';
import {Trans, t} from '@lingui/macro'
import {navigateToParametrized} from '../../lib/url';
import {locationHOC} from '../locationProvider/locationHOC';
import {getQueryParam} from '../../lib/url';
import {merge} from '../../lib/object';
const Option = Select.Option;

/**
 * @fero
 */

const DEFAULT_SEARCH_TYPE = SEARCH_TYPE.LIKE;

class SearchByLocation extends React.PureComponent {
    static propTypes = {
        textQueryParamKey: PropTypes.string.isRequired,
        typeQueryParamKey: PropTypes.string,
        paginationPageKey: PropTypes.string,
        altSearchPageKey: PropTypes.string,
        additionalParams: PropTypes.object,
        eraseParams: PropTypes.object,//params used for erase, if not specified additional params are used in erase
    };

    constructor(props) {
        super(props);
        const {location, typeQueryParamKey, textQueryParamKey} = props;
        const searchText = getQueryParam(location, textQueryParamKey);
        const searchType = typeQueryParamKey != null ? getQueryParam(location, typeQueryParamKey) : null;
        this.state = {
            searchText: searchText != null ? searchText : '',
            type: typeQueryParamKey != null ? (searchType != null ? searchType : DEFAULT_SEARCH_TYPE) : null,
        }
    }

    componentDidUpdate(prevProps) {
        if(!deepEqual(this.props.location, prevProps.location)) {
            this.setValues();
        }
    }

    setValues = () => {
        const {location, typeQueryParamKey, textQueryParamKey} = this.props;
        const searchText = getQueryParam(location, textQueryParamKey);
        const searchType = typeQueryParamKey != null ? getQueryParam(location, typeQueryParamKey) : null;

        this.setState ({
            searchText: searchText != null ? searchText : '',
            type: typeQueryParamKey != null ? (searchType != null ? searchType : DEFAULT_SEARCH_TYPE) : null,
        });
    };

    onSearch = (useAlt) => () => {
        const {location, typeQueryParamKey, textQueryParamKey, paginationPageKey, altSearchPageKey, additionalParams} = this.props;
        const {searchText, type} = this.state;
        const searchReady = searchText != null;
        let paramObject = {
            [textQueryParamKey]: searchText.length > 0 ? searchText : null,
        };

        if (typeQueryParamKey != null) {
            paramObject = merge(paramObject, {[typeQueryParamKey]: type});
        }

        if (paginationPageKey != null) {
            paramObject = merge(paramObject, {[paginationPageKey]: null});
        }

        if (additionalParams != null) {
            paramObject = merge(paramObject, additionalParams);
        }

        if(altSearchPageKey != null) {
            paramObject = merge(paramObject, {[altSearchPageKey]: (useAlt ? 1 : null)});
        }

        if (searchReady) {
            navigateToParametrized(location, null, paramObject);
        }
    };

    onTypeChange = (type) => {
        this.setState({
            type: type,
        })
    };

    onSearchTextChange = (event) => {
        const searchText = event.target.value;
        this.setState({
            searchText,
        })
    };

    onSearchErase = () => {
        const {location, typeQueryParamKey, textQueryParamKey, paginationPageKey, eraseParams, additionalParams} = this.props;
        this.setState({
            searchText: '',
        });
        let paramObject = {
            [textQueryParamKey]: undefined,
            [typeQueryParamKey]: undefined,
        };

        if (paginationPageKey != null) {
            paramObject = merge(paramObject, {[paginationPageKey]: null})
        }

        if (eraseParams != null) {
            paramObject = merge(paramObject, eraseParams);
        } else if (additionalParams != null) {
            paramObject = merge(paramObject, additionalParams);
        }

        navigateToParametrized(location, null, paramObject);
    };

    render() {
        const {searchText, type} = this.state;
        const {textQueryParamKey, typeQueryParamKey, altSearchPageKey, location, className} = this.props;

        const phrase = getQueryParam(location, textQueryParamKey);

        const after = <Button.Group>
            <Button onClick={this.onSearch()} type="primary" icon="search"/>
            <Button onClick={this.onSearchErase} type={searchText != '' ? "danger" : "primary"} icon="delete"/>
        </Button.Group>;
        const before = typeQueryParamKey != null ? <Select
            value={type}
            onChange={this.onTypeChange}
            className="search-type-select"
        >
            <Option value={SEARCH_TYPE.LIKE}><Trans>Obsahuje</Trans></Option>
            <Option value={SEARCH_TYPE.PREFIX}><Trans>Začína</Trans></Option>
            <Option value={SEARCH_TYPE.EXACT}><Trans>Zhodne</Trans></Option>
            <Option value={SEARCH_TYPE.CODE}><Trans>Vlastné ozn.</Trans></Option>
            <Option value={SEARCH_TYPE.BARCODE}><Trans>EAN</Trans></Option>
        </Select> : null;

        return <div className="d-flex flex-wrap">
            <div className={className}>
                <Input tabIndex="1" accessKey="h" addonBefore={before} size="large" addonAfter={after} value={searchText}
                   onChange={this.onSearchTextChange} onPressEnter={this.onSearch()} onFocus={(ev) => {ev.target.select()}}/>
            </div>
            { altSearchPageKey != null && searchText != null && searchText != "" && searchText == phrase ?
                <Button 
                    className="flex-grow-1"
                    size="large"
                    onClick={this.onSearch(true)}
                >
                    <Trans>Vyhľadať alternatívy</Trans>
                </Button> : null
            }
        </div>;
    }

}

export default locationHOC(SearchByLocation);